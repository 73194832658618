<template>
    <div class="m_ategories_page flex_col flex_align_center">
        <div class="nav_bar flex_align_center">
            <img class="back" src="@/assets/img_mobile/back.png" @click="$router.go(-1)" alt="">
        </div>
        <!-- <h1 class="logo HurmeGeometricSans3_Black text_center">LEDO</h1> -->
        <div class="logo_wrap flex_justify_center">
            <img class="logo" src="@/assets/img_mobile/tag_09.png" alt="">
        </div>
        <h2 class="HurmeGeometricSans3_SemiBold">All series</h2>
        <h3 class="SourceHanSansCN_Bold">所有类目产品</h3>
        <div class="container flex_col flex_align_start">
            <div class="item" v-for="(item, index) of productsData" :key="index">
                <img class="product" :src="item.mAtegoriesImg" alt="">
                <div class="info">
                    <div class="name_wrap flex_align_center flex_justify_between">
                        <span class="name PingFang_Blod">{{item.detailCn}}</span>
                        <span class="price HurmeGeometricSans3_SemiBold">￥{{item.price}}</span>
                    </div>
                    <div class="color_wrap flex_align_center flex_justify_between">
                        <div class="flex_col">
                            <span class="HurmeGeometricSans3_SemiBold">colour</span>
                            <div class="colors flex_align_center">
                                <img :src="colorTags[colorItem]" alt="" v-for="(colorItem, colorIndex) of item.colors"
                                    :key="colorIndex">
                            </div>
                        </div>
                        <a :href="item.href" target="_blank">
                            <img class="tag" src="@/assets/img_mobile/tag_06.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <m-foot></m-foot>
        <m-to-top></m-to-top>
    </div>
</template>

<script>
    import white from '@/assets/img_mobile/white_tag.png'
    import black from '@/assets/img_mobile/black_tag.png'
    import wathet from '@/assets/img_mobile/wathet_tag.png'
    import grey from '@/assets/img_mobile/grey_tag.png'

    import mToTop from "@/components/mobile/m-to-top.vue"
    import mFoot from "@/components/mobile/m-foot.vue"

    import { mapState } from 'vuex'
    export default {
        name: 'm-ategories',
        data() {
            return {
                colorTags: {
                    'white': white,
                    'black': black,
                    'wathet': wathet,
                    'grey': grey
                }
            }
        },
        computed: {
            ...mapState([
                'productsData'
            ])
        },
        components: {
            mToTop,
            mFoot
        }
    }
</script>

<style lang="scss" scoped>
    .m_ategories_page {
        min-height: 100vh;
        background-color: #2B2B2B;

        .nav_bar {
            width: 100%;
            height: 88px;
            padding: 0 30px;

            .back {
                width: 70px;
                height: 45px;
            }
        }

        .logo_wrap {
            width: 200px;
            border-bottom: 1px solid rgba(0, 255, 0, 0.57);
            padding: 80px 0 20px;

            .logo {
                width: 160px;
                height: 48px;
            }
        }


        h2,
        h3 {
            color: #00FF00;
        }

        h2 {
            line-height: 51px;
            font-size: 42px;
            margin: 12px 0 8px;
        }

        h3 {
            font-size: 30px;
        }

        .container {
            width: 100%;
            color: #FFFFFF;
            padding: 60px 0 90px 60px;

            .item {
                width: 564px;
                margin-top: 50px;

                &:first-child {
                    margin-top: 0;
                }

                .product {
                    width: 100%;
                    height: 400px;
                }

                .info {
                    padding-top: 30px;

                    .name_wrap {
                        line-height: 42px;
                        border-bottom: 1px solid #EEEDE8;
                        padding-bottom: 12px;
                        margin-bottom: 16px;

                        .name {
                            font-size: 30px;
                        }

                        .price {
                            font-size: 24px;
                        }
                    }

                    .color_wrap {
                        span {
                            font-size: 26px;
                        }

                        .colors {
                            margin-top: 10px;

                            img {
                                width: 22px;
                                height: 22px;
                                margin-left: 18px;

                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }

                        .tag {
                            width: 70px;
                            height: 70px;
                            margin-right: -10px;
                        }
                    }

                }
            }
        }
    }
</style>